import { http } from '@/http/axios.js'

// 列表
export function getListAPI(params) {
  return http({
    url: `/api/company/store/order/invoice/list`,
    method: 'get',
    params
  })
}
export function changeStatusAPI(data) {
  return http({
    url: `/api/company/store/order/invoice/updateStatus`,
    method: 'post',
    data
  })
}
export function delAPI(id) {
  return http({
    url: `/api/company/store/order/invoice/del`,
    method: 'post',
    data: { id }
  })
}