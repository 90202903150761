<template>
  <!--  -->
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <!-- 搜索 -->
    <el-form ref="form" inline :model="searchData" label-width="">
      <el-form-item label="订单号">
        <el-input style="width: 200px" clearable v-model.trim="searchData.order_sn" size="mini" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="手机号">
        <el-input style="width: 200px" clearable v-model.trim="searchData.phone" size="mini" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="抬头">
        <el-input style="width: 200px" clearable v-model.trim="searchData.name" size="mini" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="税号">
        <el-input style="width: 200px" clearable v-model.trim="searchData.duty_paragraph" size="mini" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label=" ">
        <el-button type="primary" size="mini" icon="el-icon-search" @click="getList">搜索</el-button>
        <el-button type="info" size="mini" icon="el-icon-refresh" @click="reset">重置</el-button>
      </el-form-item>
    </el-form>
    <div class="tabs">
      <div :class="['item', searchData.status === -1 ? 'active' : '']" @click="handleClickTab(-1)">
        <el-badge>
          <span class="tab-name">全部</span>
        </el-badge>
      </div>
      <div :class="['item', searchData.status === 0 ? 'active' : '']" @click="handleClickTab(0)">
        <el-badge>
          <span class="tab-name">待开票</span>
        </el-badge>
      </div>
      <div :class="['item', searchData.status === 20 ? 'active' : '']" @click="handleClickTab(20)">
        <el-badge>
          <span class="tab-name">已开票</span>
        </el-badge>
      </div>
      <div :class="['item', searchData.status === 10 ? 'active' : '']" @click="handleClickTab(10)">
        <el-badge>
          <span class="tab-name">已拒绝</span>
        </el-badge>
      </div>
    </div>

    <!-- list -->
    <el-table :data="list">
      <el-table-column prop="order_sn" label="订单号" min-width="150">
        <template slot-scope="{ row }">
          {{ row['order']?.['order_sn'] }}
        </template>
      </el-table-column>
      <el-table-column prop="name" label="抬头" min-width="120" show-overflow-tooltip> </el-table-column>
      <el-table-column prop="duty_paragraph" label="税号" min-width="120" show-overflow-tooltip> </el-table-column>
      <el-table-column prop="address" label="地址" min-width="120" show-overflow-tooltip> </el-table-column>
      <el-table-column prop="phone" label="电话" min-width="120" show-overflow-tooltip> </el-table-column>
      <el-table-column prop="bank" label="开户银行" min-width="120" show-overflow-tooltip> </el-table-column>
      <el-table-column prop="bank_number" label="银行账户" min-width="120" show-overflow-tooltip> </el-table-column>
      <el-table-column prop="title" label="状态" min-width="100" show-overflow-tooltip>
        <template slot-scope="{ row }">
          <el-tag v-if="row.status == 0" type="warning" size="mini">待开票</el-tag>
          <el-tag v-if="row.status == 10" type="danger" size="mini">已拒绝</el-tag>
          <el-tag v-if="row.status == 20" type="primary" size="mini">已开票</el-tag>
        </template>
      </el-table-column>

      <el-table-column prop="created_at" label="申请时间" min-width="160"></el-table-column>
      <el-table-column label="操作" width="150" fixed="right">
        <template slot-scope="{ row }">
          <el-link style="margin-right: 10px" type="primary" :underline="false" @click="handleDetail(row)"> 详情 </el-link>
          <el-link style="margin-right: 10px" type="primary" :underline="false" @click="handleReview(row)">审核</el-link>
          <el-link type="danger" :underline="false" @click="handleDel(row)">删除</el-link>
        </template>
      </el-table-column>
    </el-table>
    <pagination :total="total" :page.sync="searchData.page" :limit.sync="searchData.page_size" @pagination="getList" />
    <!-- 审核 -->
    <el-dialog title="审核" :visible.sync="show_review" width="width">
      <el-form ref="formRef" :model="reviewData">
        <el-form-item label="状态：">
          <el-radio-group v-model="reviewData.status">
            <el-radio :label="20">已开票</el-radio>
            <el-radio :label="10">已拒绝</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="show_review = false" size="mini">取 消</el-button>
        <el-button type="primary" @click="confirmStatus" size="mini">提 交</el-button>
      </div>
    </el-dialog>
    <!-- 详情 -->
    <el-drawer :title="detail.order_sn" size="50%" :visible.sync="show_detail" direction="rtl">
      <div class="detail-box">
        <el-descriptions direction="vertical" :column="4" border>
          <el-descriptions-item label="创建时间">{{ detail.created_at }}</el-descriptions-item>
          <el-descriptions-item label="寄存时间">{{ detail.start_time }} ~ {{ detail.end_time }}</el-descriptions-item>
          <el-descriptions-item label="支付时间">{{ detail.pay_time }}</el-descriptions-item>
          <el-descriptions-item label="状态">{{ detail.storage_status_dsc }}</el-descriptions-item>
          <el-descriptions-item label="支付方式">{{ detail.pay_method_dsc }}</el-descriptions-item>
          <el-descriptions-item label="支付金额">{{ detail.pay_money }}</el-descriptions-item>
          <el-descriptions-item label="小件">{{ detail.small_luggage_quantity }}</el-descriptions-item>
          <el-descriptions-item label="大件">{{ detail.large_luggage_quantity }}</el-descriptions-item>
          <el-descriptions-item label="行李照片" :span="4">
            <div class="photos">
              <el-image
                style="width: 70px; height: 70px; border-radius: 4px; margin-right: 8px"
                v-for="(item, i) in detail.luggage_photos"
                :key="i"
                :src="item"
                :preview-src-list="[item]"
                fit="cover"
              ></el-image>
            </div>
          </el-descriptions-item>
        </el-descriptions>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { getListAPI, changeStatusAPI, delAPI } from './api'
import { authBtnMixin } from '@/mixins/authBtnMixin'

export default {
  name: 'Index',
  mixins: [authBtnMixin],

  data() {
    return {
      show_review: false,
      show_detail: false,
      list: [],
      searchData: {
        page: 1,
        page_size: 10,
        order_sn: '',
        status: -1,
        phone: '',
        name: '',
        duty_paragraph: ''
      },
      total: 0,
      reviewData: {
        id: '',
        status: 20
      },
      detail: {}
    }
  },

  watch: {},
  activated() {
    this.getList()
  },
  mounted() {},

  methods: {
    // 列表
    async getList() {
      let params = { ...this.searchData }
      if (params.status == -1) {
        params.status = ''
      }
      const res = await getListAPI(params)
      this.total = res.total
      this.list = res.data
    },

    // 点击tab
    handleClickTab(i) {
      if (this.searchData.status == i) return
      this.searchData.status = i
      this.getList()
    },
    // 审核按钮
    handleReview({ id }) {
      this.show_review = true
      this.reviewData = {
        id,
        status: 20
      }
    },
    // 确认状态
    confirmStatus() {
      changeStatusAPI(this.reviewData).then(() => {
        this.getList()
        this.$message.success('提交成功')
        this.show_review = false
      })
    },
    // 详情
    handleDetail(row) {
      this.detail = row.order
      this.show_detail = true
    },
    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    },
    handleDel({ id }) {
      this.$confirm('确认删除该条数据吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delAPI(id).then(() => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.getList()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;

  .tabs {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 1px solid #dcdfe6;

    .item {
      cursor: pointer;
      position: relative;
      padding-bottom: 5px;
      margin-right: 20px;
      // border-bottom: 2px solid #409eff;

      .tab-name {
        padding: 6px;
      }
    }

    .active {
      color: #409eff;
      font-weight: 600;

      &::after {
        content: '';
        display: inline-block;
        height: 2px;
        width: 25px;
        background: #409eff;
        position: absolute;
        bottom: -2px;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 5px;
      }
    }
  }
  .detail-box {
    padding: 0 20px;
  }
  .photos {
    display: flex;
    align-items: center;
  }
}
</style>
